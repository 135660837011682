import { useHireContext } from "../../HireContext";
import HeaderLogo from "../HeaderLogo";
import Question from "../Question";
import "../../HireStyle.css";
import DashedCard from "../DashedCard";
import { FaCheck } from "react-icons/fa6";
import { useEffect, useRef, useState } from "react";
import Card from "../Card";
import {
  checkout_team,
  checkout_basic,
  checkout_pro,
} from "../../../pricing/checkout";
import emailjs from "@emailjs/browser";
import { prolevantHubspotUrl } from "../../../../helper/env";
interface selectOptionsType {
  value: string;
  label: string;
  isSelected: boolean;
}

export default function Step1() {
  const emailRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const [requiredName, setRequiredName] = useState(false);
  const [requiredEmail, setRequiredEmail] = useState(false);
  useEffect(() => {
    if (emailRef.current) emailRef.current.value = formData?.email || "";
    if (nameRef.current) nameRef.current.value = formData?.userName || "";
  }, []);
  const {
    questions,
    stepNumber,
    totalNumber,
    setQuestions,
    formData,
    setFormData,
    transitionForPrevStep,
  } = useHireContext();
  const [isLoading, setIsLoading] = useState(false);
  const onBasicPlan = async () => {
    setRequiredName(true);
    setRequiredEmail(true);

    setIsLoading(true);
    const response = await checkout_basic();
    if (!emailRef.current?.validity.valid || emailRef.current?.value === "")
      emailRef.current?.focus();
    else if (!nameRef.current?.validity.valid) {
      nameRef.current?.focus();
    } else {
      window.open(response.data, "_blank");
    }
    setIsLoading(false);
  };
  const onTeamPlan = async () => {
    setRequiredName(true);
    setRequiredEmail(true);
    setIsLoading(true);
    const response = await checkout_team();
    if (!emailRef.current?.validity.valid || emailRef.current?.value === "")
      emailRef.current?.focus();
    else if (!nameRef.current?.validity.valid) {
      nameRef.current?.focus();
    } else {
      window.open(response.data, "_blank");
    }
    setIsLoading(false);
  };
  const onProPlan = async () => {
    setRequiredName(true);
    setRequiredEmail(true);
    setIsLoading(true);
    const response = await checkout_pro();
    if (!emailRef.current?.validity.valid || emailRef.current?.value === "")
      emailRef.current?.focus();
    else if (!nameRef.current?.validity.valid) {
      nameRef.current?.focus();
    } else {
      window.open(response.data, "_blank");
    }
    setIsLoading(false);
  };
  const sentFormToEmail = async () => {
    const skills: string[] = [];
    formData?.skills.forEach((item, index) => {
      skills.push(item.label);
    });
    const summery = `
Specializaion : ${formData?.specializaion}
Skills : ${skills.join(" , ")}`;
    var templateParams = {
      email: formData?.email,
      name: formData?.userName,
      summery: summery,
    };
    await emailjs
      .send("service_bp8ceca", "template_htx0xdl", templateParams)
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setIsLoading(false);
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };
  useEffect(() => {
    const arrayOfQuestions = [...questions];
    arrayOfQuestions[2].question = `We suggest you the following package: ${
      formData?.skills.length! <= 1
        ? "Basic"
        : formData?.skills.length! <= 3
        ? "Team"
        : formData?.skills.length! <= 6
        ? "Pro"
        : "Custom "
    }`;
    setQuestions(arrayOfQuestions);
    console.log("arrayOfQuestions", arrayOfQuestions);
  }, []);
  return (
    <div
      style={{
        padding: "2rem",
      }}
    >
      <HeaderLogo />
      <hr />
      <Question
        question={questions[stepNumber - 1].question}
        questionNumber={stepNumber}
        totalQuestion={totalNumber}
      />
      <div>
        <div
          style={{
            color: "#b7b7b7",
            marginBlock: "1rem",
            fontSize: "1.2rem",
          }}
        >
          specializaion <FaCheck style={{ marginInline: "0.5rem" }} />
        </div>
        <DashedCard
          withClose={false}
          title={formData?.specializaion || ""}
          onClose={() => {}}
        />
        <div
          style={{
            color: "#b7b7b7",
            marginBlock: "1rem",
            fontSize: "1.2rem",
          }}
        >
          Tech stack <FaCheck style={{ marginInline: "0.5rem" }} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "0.3rem",
          }}
        >
          {formData?.skills.map((e) => (
            <div key={e.label}>
              <DashedCard
                withClose={false}
                title={e.label}
                onClose={() => {}}
              />
            </div>
          ))}
        </div>
      </div>
      <form
        style={{
          marginBlockStart: "2rem",
          marginBlockEnd: "1rem",
        }}
      >
        <div>
          {/* <label htmlFor="name">Name :</label> */}
          <input
            ref={nameRef}
            required={requiredName}
            type="text"
            placeholder="Name"
            name="name"
            id="inputName"
            onChange={(e) => {
              setFormData({ ...formData!, userName: e.target.value });
            }}
            style={{
              paddingInline: "1rem",
              width: "100%",
              backgroundColor: "black",
              outline: "none",
              border: "2px solid black",
              borderRadius: "8px",
              height: "3rem",
              color: "White",
              marginBlockEnd: "1rem",
            }}
          />
        </div>
        <div>
          {/* <label
          htmlFor="email"
          style={{
            color: "black",
            marginBlockEnd: "0.2rem",
          }}
        >
          Email :
        </label> */}
          <input
            ref={emailRef}
            required={requiredEmail}
            type="email"
            name="email"
            id={"inputEmail"}
            placeholder="Email"
            onChange={(e) => {
              setFormData({ ...formData!, email: e.target.value });
            }}
            style={{
              outline: "2px solid transparent",
              paddingInline: "1rem",
              width: "100%",
              backgroundColor: "black",
              border: "none",
              borderRadius: "8px",
              height: "3rem",
              color: "White",
            }}
          />
        </div>
      </form>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "1rem",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* <button
          onClick={() => {
            setRequiredName(true);
            setRequiredEmail(true);
            if (
              !emailRef.current?.validity.valid ||
              emailRef.current?.value === ""
            )
              emailRef.current?.focus();
            else if (!nameRef.current?.validity.valid) {
              nameRef.current?.focus();
            } else {
              window.open(
                prolevantHubspotUrl,
                "_blank"
              );
            }
          }}
          className=""
          style={{
            backgroundColor: "#17181a",
            padding: "0.5rem",
            color: "white",
            width: "100%",
            borderRadius: "0.5rem",
            minHeight: "3rem",
            border: "none",
            outline: "none",
          }}
        >
          Schedule a call with the Prolevant team
        </button>

        <button
          onClick={
            formData?.skills.length! <= 1
              ? onBasicPlan
              : formData?.skills.length! <= 3
              ? onTeamPlan
              : onProPlan
          }
          className=""
          style={{
            minHeight: "3rem",
            padding: "0.5rem",
            backgroundColor: "#17181a",
            color: "white",
            width: "100%",
            borderRadius: "0.5rem",
            border: "none",
            outline: "none",
          }}
        >
          {!isLoading ? (
            `We recommend the following package : ${
              formData?.skills.length! <= 1
                ? "Basic"
                : formData?.skills.length! <= 3
                ? "Team"
                : "Pro"
            }`
          ) : (
            <i className="icon-spinner icon-spin"></i>
          )}
        </button> */}
        <button
          onClick={async () => {
            setIsLoading(true);
            setRequiredName(true);
            setRequiredEmail(true);
            if (
              !emailRef.current?.validity.valid ||
              emailRef.current?.value === ""
            ) {
              emailRef.current?.focus();
              setIsLoading(false);
            } else if (!nameRef.current?.validity.valid) {
              nameRef.current?.focus();
              setIsLoading(false);
            } else {
              await sentFormToEmail();
              window.open(prolevantHubspotUrl, "_blank");
            }
          }}
          className=""
          style={{
            backgroundColor: "#17181a",
            padding: "0.5rem",
            color: "white",
            width: "100%",
            borderRadius: "0.5rem",
            minHeight: "3rem",
            border: "none",
            outline: "none",
          }}
        >
          {!isLoading ? (
            `Connect with Prolevant team`
          ) : (
            <i className="icon-spinner icon-spin"></i>
          )}
        </button>
        {/* <Card
          icon={undefined}
          withOutRadio={true}
          title={"Schedule a call with the Prolevant team"}
          onClick={() => {
            if (
              !emailRef.current?.validity.valid ||
              emailRef.current?.value === ""
            )
              emailRef.current?.focus();
            else if (!nameRef.current?.validity.valid) {
              nameRef.current?.focus();
            } else {
              window.open(
                prolevantHubspotUrl,
                "_blank"
              );
            }
          }}
        /> */}
        {/* <Card
          icon={undefined}
          withOutRadio={true}
          title={"We recommend the following package"}
          onClick={() => {
            if (
              !emailRef.current?.validity.valid ||
              emailRef.current?.value === ""
            )
              emailRef.current?.focus();
            else if (!nameRef.current?.validity.valid) {
              nameRef.current?.focus();
            } else {
              console.log(formData);
            }
          }}
        /> */}
      </div>
      {/* <div
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            marginBlockStart: "1rem",
          }}
        >
          <Card
            icon={undefined}
            withOutRadio={true}
            title={"Schedule a call with the Prolevant team"}
            onClick={() => {
              if (
                !emailRef.current?.validity.valid ||
                emailRef.current?.value === ""
              )
                emailRef.current?.focus();
              else if (!nameRef.current?.validity.valid) {
                nameRef.current?.focus();
              } else {
                window.open(
                  prolevantHubspotUrl,
                  "_blank"
                );
              }
            }}
          />
        </div>
        <div
          style={{
            marginBlockStart: "1rem",
          }}
        >
          <Card
            icon={undefined}
            withOutRadio={true}
            title={"We recommend the following package"}
            onClick={() => {
              if (
                !emailRef.current?.validity.valid ||
                emailRef.current?.value === ""
              )
                emailRef.current?.focus();
              else if (!nameRef.current?.validity.valid) {
                nameRef.current?.focus();
              } else {
                console.log(formData);
              }
            }}
          />
        </div>
      </div> */}
      {stepNumber !== 1 && (
        <button
          className="back-btn"
          style={{ width: "100%", marginBlockStart: "3rem" }}
          onClick={() => {
            transitionForPrevStep();
          }}
        >
          <div className="back-text">Back</div>
        </button>
      )}
    </div>
  );
}
