import { ReactNode, useEffect, useRef, useState } from "react";
import "../HireStyle.css";
import { useHireContext } from "../HireContext";
import { prolevantHubspotUrl } from "../../../helper/env";
interface CardPropsType {
  icon: ReactNode;
  title: string;
  onClick?: () => void;
  isLink?: boolean;
  isChecked?: boolean;
  withOutRadio?: boolean;
}

export default function Card({
  icon,
  title,
  onClick,
  isLink = false,
  withOutRadio = false,
  isChecked = false,
}: CardPropsType) {
  const [radioState, setRadioState] = useState(false);
  const [checkedElement, setCheckedElement] = useState<any>(null);
  const radioRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isChecked) {
      const element: any = document.getElementsByClassName(
        `radio-inside-card-${title}`
      );
      setCheckedElement(element);
    }
  }, [isChecked]);

  if (!isLink) {
    return (
      <div
        onClick={() => {
          if (radioRef.current) {
            radioRef.current.click();
          }
          onClick && onClick();
        }}
        className={`hire-card ${isChecked ? "active-card" : ""}`}
        style={{
          position: "relative",
        }}
      >
        {icon} {title}
        {!withOutRadio && (
          <input
            id={`radio-inside-card`}
            className={`radio-inside-card-${title}`}
            ref={radioRef}
            style={{
              position: "absolute",
              insetBlockStart: "3px",
              insetInlineStart: "10px",
            }}
            type="radio"
            value={title}
            name="radio"
            onChange={() => {
              onClick && onClick();
            }}
          />
        )}
        {/* {!withOutRadio && (
          <span
            className={`border-for-radio-btn ${
              isChecked ? "border-for-radio-btn-active" : ""
            } `}
          ></span>
        )} */}
      </div>
    );
  } else {
    return (
      <a href={prolevantHubspotUrl}>
        <div
          onClick={() => {
            radioRef.current?.click();
            onClick && onClick();
          }}
          className="hire-card "
          style={{
            position: "relative",
          }}
        >
          {title}
        </div>
      </a>
    );
  }
}
