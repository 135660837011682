import Card from "../Card";
import "../../HireStyle.css";
import { useEffect, useRef, useState } from "react";
import { useHireContext } from "../../HireContext";
import {
  checkout_team,
  checkout_basic,
  checkout_pro,
} from "../../../pricing/checkout";
import emailjs from "@emailjs/browser";
import { prolevantHubspotUrl } from "../../../../helper/env";

export default function Step3() {
  const emailRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [requiredName, setRequiredName] = useState(false);
  const [requiredEmail, setRequiredEmail] = useState(false);

  const { formData, setFormData, setQuestions, questions } = useHireContext();
  const onBasicPlan = async () => {
    setRequiredName(true);
    setRequiredEmail(true);

    setIsLoading(true);
    const response = await checkout_basic();
    if (!emailRef.current?.validity.valid || emailRef.current?.value === "")
      emailRef.current?.focus();
    else if (!nameRef.current?.validity.valid) {
      nameRef.current?.focus();
    } else {
      window.open(response.data, "_blank");
    }
    setIsLoading(false);
  };
  const onTeamPlan = async () => {
    setRequiredName(true);
    setRequiredEmail(true);
    setIsLoading(true);
    const response = await checkout_team();
    if (!emailRef.current?.validity.valid || emailRef.current?.value === "")
      emailRef.current?.focus();
    else if (!nameRef.current?.validity.valid) {
      nameRef.current?.focus();
    } else {
      window.open(response.data, "_blank");
    }
    setIsLoading(false);
  };
  const onProPlan = async () => {
    setRequiredName(true);
    setRequiredEmail(true);
    setIsLoading(true);
    const response = await checkout_pro();
    if (!emailRef.current?.validity.valid || emailRef.current?.value === "")
      emailRef.current?.focus();
    else if (!nameRef.current?.validity.valid) {
      nameRef.current?.focus();
    } else {
      window.open(response.data, "_blank");
    }
    setIsLoading(false);
  };
  const sentFormToEmail = async () => {
    const skills: string[] = [];
    formData?.skills.forEach((item, index) => {
      skills.push(item.label);
    });
    const summery = `
Specializaion : ${formData?.specializaion}
Skills : ${skills.join(" , ")}`;
    var templateParams = {
      email: formData?.email,
      name: formData?.userName,
      summery: summery,
    };

    await emailjs
      .send("service_bp8ceca", "template_htx0xdl", templateParams)
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setIsLoading(false);
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };
  useEffect(() => {
    const arrayOfQuestions = [...questions];
    arrayOfQuestions[2].question = `We suggest you the following package: ${
      formData?.skills.length! <= 1
        ? "Basic"
        : formData?.skills.length! <= 3
        ? "Team"
        : formData?.skills.length! <= 6
        ? "Pro"
        : "Custom "
    }`;
    setQuestions(arrayOfQuestions);
    console.log("arrayOfQuestions", arrayOfQuestions);
  }, []);
  useEffect(() => {
    if (emailRef.current) emailRef.current.value = formData?.email || "";
    if (nameRef.current) nameRef.current.value = formData?.userName || "";
  }, []);
  return (
    <div
      style={{
        width: "100%",
      }}
      className="cards-Answer-container"
    >
      <form style={{ width: "100%", margin: 0 }}>
        <div style={{ marginBlockEnd: "1rem" }}>
          {/* <label htmlFor="name">Name :</label> */}
          <input
            ref={nameRef}
            required={requiredName}
            type="text"
            placeholder="Name"
            name="name"
            id="inputName"
            onChange={(e) => {
              setFormData({ ...formData!, userName: e.target.value });
            }}
            style={{
              paddingInline: "1rem",
              width: "100%",
              backgroundColor: "black",
              outline: "none",
              border: "2px solid black",
              borderRadius: "8px",
              height: "3rem",
              color: "White",
            }}
          />
        </div>
        <div style={{ margin: 0 }}>
          {/* <label
          htmlFor="email"
          style={{
            color: "black",
            marginBlockEnd: "0.2rem",
          }}
        >
          Email :
        </label> */}
          <input
            ref={emailRef}
            required={requiredEmail}
            type="email"
            name="email"
            id={"inputEmail"}
            placeholder="Email"
            onChange={(e) => {
              setFormData({ ...formData!, email: e.target.value });
            }}
            style={{
              outline: "2px solid transparent",
              paddingInline: "1rem",
              width: "100%",
              backgroundColor: "black",
              border: "none",
              borderRadius: "8px",
              height: "3rem",
              color: "White",
            }}
          />
        </div>
      </form>
      <div
        style={{
          marginBlock: "0.1rem",
          display: "flex",
          justifyContent: "center ",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* <button
          onClick={() => {
            setRequiredName(true);
            setRequiredEmail(true);
            if (
              !emailRef.current?.validity.valid ||
              emailRef.current?.value === ""
            )
              emailRef.current?.focus();
            else if (!nameRef.current?.validity.valid) {
              nameRef.current?.focus();
            } else {
              window.open(
                prolevantHubspotUrl,
                "_blank"
              );
            }
          }}
          className=""
          style={{
            backgroundColor: "black",
            padding: "1rem 2rem",
            color: "white",
            width: "49%",
            borderRadius: "0.5rem",
            minHeight: "5rem",
            border: "none",
            outline: "none",
          }}
        >
          Schedule a call with the Prolevant team
        </button>

        <button
          onClick={
            formData?.skills.length! <= 1
              ? onBasicPlan
              : formData?.skills.length! <= 3
              ? onTeamPlan
              : onProPlan
          }
          className=""
          style={{
            minHeight: "5rem",
            padding: "1rem 2rem",
            backgroundColor: "black",
            color: "white",
            width: "49%",
            borderRadius: "0.5rem",
            border: "none",
            outline: "none",
          }}
        >
          {!isLoading ? (
            `We recommend the following package : ${
              formData?.skills.length! <= 1
                ? "Basic"
                : formData?.skills.length! <= 3
                ? "Team"
                : "Pro"
            }`
          ) : (
            <i className="icon-spinner icon-spin"></i>
          )}
        </button> */}
        <button
          onClick={async () => {
            setIsLoading(true);
            setRequiredName(true);
            setRequiredEmail(true);
            if (
              !emailRef.current?.validity.valid ||
              emailRef.current?.value === ""
            ) {
              emailRef.current?.focus();
              setIsLoading(false);
            } else if (!nameRef.current?.validity.valid) {
              nameRef.current?.focus();
              setIsLoading(false);
            } else {
              await sentFormToEmail();
              window.open(prolevantHubspotUrl, "_blank");
            }
          }}
          className=""
          style={{
            backgroundColor: "black",
            padding: "0.8rem 2rem",
            color: "white",
            width: "49%",
            borderRadius: "0.5rem",
            minHeight: "2rem",
            border: "none",
            outline: "none",
          }}
        >
          {!isLoading ? (
            `Connect with Prolevant team`
          ) : (
            <i className="icon-spinner icon-spin"></i>
          )}
        </button>
        {/* <Card
          icon={undefined}
          withOutRadio={true}
          title={"Schedule a call with the Prolevant team"}
          onClick={() => {
            if (
              !emailRef.current?.validity.valid ||
              emailRef.current?.value === ""
            )
              emailRef.current?.focus();
            else if (!nameRef.current?.validity.valid) {
              nameRef.current?.focus();
            } else {
              window.open(
                prolevantHubspotUrl,
                "_blank"
              );
            }
          }}
        /> */}
        {/* <Card
          icon={undefined}
          withOutRadio={true}
          title={"We recommend the following package"}
          onClick={() => {
            if (
              !emailRef.current?.validity.valid ||
              emailRef.current?.value === ""
            )
              emailRef.current?.focus();
            else if (!nameRef.current?.validity.valid) {
              nameRef.current?.focus();
            } else {
              console.log(formData);
            }
          }}
        /> */}
      </div>
    </div>
  );
}
