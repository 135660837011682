import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();

  return (
    <footer
      id="footer"
      className="dark border-top-0"
      style={{ backgroundColor: "#0a0a0a" }}
    >
      <div className="px-5 container-fluid">
        {/* <!-- Footer Widgets
				============================================= --> */}
        <div className="footer-widgets-wrap">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="widget clearfix">
                <div className="row">
                  <div className="col-md col-xs-12 mt-3 mt-sm-0">
                    <h4 className="mb-2">Company</h4>
                    <ul className="mb-0" style={{ listStyleType: "none" }}>
                      <li>
                        <a
                          href="/"
                          // onClick={() => {
                          //   navigate("/");
                          // }}
                        >
                          Home
                        </a>
                      </li>
                      <li>
                        <a
                          href="/#/work"
                          // onClick={() => {
                          //   navigate("/work");
                          // }}
                        >
                          Work
                        </a>
                      </li>
                      {/* <li>
                        <a
                          href="/#/hire"
                          // onClick={() => {
                          //   navigate("/work");
                          // }}
                        >
                          Hire
                        </a>
                      </li> */}
                      <li>
                        <a
                          href="/#/pricing"
                          // onClick={() => {
                          //   navigate("/pricing");
                          // }}
                        >
                          Pricing
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="col-md col-xs-12 mt-3 mt-sm-0">
                    <h4 className="mb-2">Contact</h4>
                    <ul className="mb-0" style={{ listStyleType: "none" }}>
                      <li>
                        <a href="mailto:hello@prolevantstudio.com">
                          hello@prolevantstudio.com
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="col-md col-xs-12 mt-3 mt-sm-0">
                    <h4 className="mb-2">Headquarters</h4>
                    <ul className="mb-0" style={{ listStyleType: "none" }}>
                      <li>Dubai, United Arab Emirates</li>
                      <li>London, United Kingdom</li>
                    </ul>
                  </div>

                  <div className="col-md col-xs-12 mt-3 mt-sm-0">
                    <h4 className="mb-2">Social</h4>
                    <ul className="mb-0" style={{ listStyleType: "none" }}>
                      {/* <!-- <li><a href="#">X</a></li> --> */}
                      <li>
                        <a href="https://www.linkedin.com/company/prolevant-technology/">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="col-md col-xs-12 text-md-end mt-3 mt-sm-0">
                    <ul className="mb-0" style={{ listStyleType: "none" }}>
                      <li>
                        <a
                          href="/#/privacy"
                          // onClick={() => {
                          //   navigate("/privacy");
                          // }}
                        >
                          Privacy policy
                        </a>
                      </li>
                      <li>
                        <a
                          href="/#/terms"
                          // onClick={() => {
                          //   navigate("/terms");
                          // }}
                        >
                          Terms
                        </a>
                      </li>
                    </ul>
                    <br />
                    <p className="mb-0">
                      © 2025 Prolevant
                      <br />
                      All Rights Reserved
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-none d-md-block d-lg-none bottommargin-sm"></div>
            </div>

            <div className="w-100 d-block d-md-block d-lg-none line"></div>
          </div>
        </div>
        {/* <!-- .footer-widgets-wrap end --> */}
      </div>
    </footer>
  );
}
