import { useNavigate } from "react-router-dom";
import { prolevantHubspotUrl } from "../helper/env";

export default function IntroSection() {
  const navigate = useNavigate();

  return (
    <section id="slider" className="section mt-0 pt-0">
      <div className="container" style={{ zIndex: 2 }}>
        <div className="row text-center">
          <div className="col-md-12">
            <div className="heading-block mb-0 border-bottom-0 px-4 mx-auto mt-6 text-dark">
              <h1
                className="mb-5 mx-auto"
                style={{ maxWidth: "1000px", letterSpacing: "-2px !important" }}
              >
                <span className="nocolor">
                  <span
                    style={{
                      textTransform: "none",
                      fontWeight: "400",
                      letterSpacing: 0,
                      color: "#0a0a0a",
                    }}
                  >
                    Build your dev team for 70% less cost in just a few minutes
                  </span>
                </span>
              </h1>
              <p className="lead mb-5" style={{ color: "#000000" }}>
                We are an award-winning software development studio that makes
                it simple and affordable for startups to work with top
                developers.
              </p>
              <div>
                <a
                  href="/#/hire"
                  // onClick={() => {
                  //   navigate("/");
                  // }}
                  className="button button-circle button-dark"
                >
                  Get started
                </a>
                <a
                  href={prolevantHubspotUrl}
                  target="_blank"
                  className="button button-border button-circle button-dark"
                  style={{ boxShadow: "inset 0 0 0 2px #000000", border: 0 }}
                >
                  Book a call
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
